import React from 'react';
import RelatedPosts from '@/components/modules/RelatedPosts';
import { SectionRelatedPostsTypes } from './SectionRelatedPosts.types';

export default function SectionRelatedPosts({ title, type, more_details_button_label }: SectionRelatedPostsTypes) {
  return (
    <div className="mt-22 mb-22 mt-lg-42 mb-lg-42">
      <RelatedPosts slug={type} title={title} label={more_details_button_label} />
    </div>
  );
}
